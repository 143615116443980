#filters-vertical #bitbag_elasticsearch_search_facets label {
    display: block !important;
}

.results {
    margin-top: 0.5rem;
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    min-width: 100%;
    width: 600px;
    padding: 0 1rem 0 1rem;
    background-color: white;
    backdrop-filter: blur(1px);
    z-index: 16;
    box-shadow: 0px 1px 2px 0px #d4d4d5, 0px 0px 0px 1px #d4d4d5;
    visibility: hidden;
    color: #050428;
    max-height: 50vh;
    overflow-x: auto;

    @media (max-width: 767px) {
        width: 300px;
    }

    .result {
        padding: 1px;

        &__category {
            padding-top:  1rem;
            color: #050428;
        }

        &__link {
            padding-top: 1rem;

            .result__container {
                max-height: 400px;
                box-shadow: 0px 1px 1px 0px #d4d4d5, 0px 0px 0px 1px #d4d4d5;
                color: #050428;
                display: flex;
                flex-direction: row;
                align-items: center;

                .result__image {
                    max-width: 7rem;
                    max-height: 7rem;
                    width: 100%;
                    padding: 0.5rem;
                    object-fit: cover;

                    @media (max-width: 767px){
                        max-width: 6rem;
                        max-height: 6rem;
                    }
                }

                .result__content {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    font-weight: bold;
                    padding: 0.5rem 3.7rem 0 0;


                    @media (max-width: 767px) {
                        flex-direction: column;
                        justify-content: space-evenly;
                        text-align: left;
                        width: 100%;
                        padding: 1rem;
                    }

                    .result__price {
                        position: absolute;
                        right: 1.5rem;

                        @media (max-width: 767px) {
                            position: relative;
                            right: 0;
                        }
                    }
                }

                .result__description {
                    padding: 10px;
                }
            }

            .result__container:hover {
                background-color: rgba(250, 250, 250, 0.952);
            }
        }
    }
}
